.about-us {
  width: 90%;
  margin: 5em auto 0;
  padding: 2em;
  box-sizing: border-box;
  border: 3px solid $clr-white-full;
  border-radius: 5%;
}

.text-area {
  width: 90vw;
  max-width: $max-content-width;
  margin: 0 auto;
  padding-top: 3em;

  summary {
    outline: none;
    display: inline-block;
    color: $clr-yellow-main;
    font-family: $font-family-heading;
    cursor: pointer;


    &:hover {
      color: $clr-white-full;
    }
  }

  details[open] summary {
    position:absolute;
    color: $clr-yellow-main;
    bottom:-25px;
    left: 0;
  }
  details[open] {
    .open {
      display: none;
    }
    .close {
      display: unset;
    }
  }
  details {
    .open {
      display: unset;
    }
    .close {
      display: none;
    }
  }

  details {
    position:relative;
    margin-bottom: 25px;
  }
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

.showcase {
  width: 90vw;
  max-width: $max-content-width;
  margin: 0 auto;

  @media (min-width: $large-device) {
    margin-top: 10em;
  }

  .showcase-item {
    margin-top: 5em;

    @media (min-width: $large-device) {
      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-column-gap: 2em;
      margin-top: 10em;

      h2 {
        margin-top: 2em;
      }

      &:nth-of-type(2) {
        grid-template-columns: 2fr 1fr;

        .slider, picture {
          grid-column: 1;
          grid-row: 2;
        }

        h2 {
          grid-column: 2;
          grid-row: 2;
          justify-self: right;
        }
      }
    }

    &:last-of-type {
      margin-bottom: 5em;
    }
  }

  .imageborder img {
    border: 5px solid $clr-white-full;
  }
}

.credits {
  font-weight: lighter;
  font-size: 16px;
}

.contact-section {
  margin-top: 2em;
  text-align: center;

  .contact-box-container {
    margin-top: 5em;
    margin-bottom: 5em;
    max-width: $max-content-width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: $medium-device) {
      flex-direction: row;
      justify-content: space-evenly;
    }
  }
  h1, {
    text-align: center;
  }
  .contact-box {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
    margin-bottom: 1em;
    padding-bottom: 1em;
    border-bottom: 1px solid $clr-yellow-main;

    @media (min-width: $medium-device) {
      border: none;
    }

    .phone-icon {
      transform: translateX(7px);
    }

    .contact-information {
      width: 200px;
      padding-left: 2em;
      text-align: left;
      color: $clr-yellow-main;
      line-height: 1;

      p {
        font-family: $font-family-heading;
      }
    }
  }
}

.impressum-datenschutz {
  padding-bottom: 3em;

  &:first-of-type {
    border-bottom: 1px solid $clr-yellow-main;
  }
  h1,h2 {
    margin-bottom: 2em;
  }
  h3 {
    margin-top: 2em;
  }
  .impressum-text {
   margin-top: 2em;
  }
}
