/* kreon-regular - latin */
@font-face {
  font-family: 'Kreon';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/kreon-v24-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/kreon-v24-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/kreon-v24-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/kreon-v24-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/kreon-v24-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/kreon-v24-latin-regular.svg#Kreon') format('svg'); /* Legacy iOS */
}

/* kreon-500 - latin */
@font-face {
  font-family: 'Kreon';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/kreon-v24-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/kreon-v24-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/kreon-v24-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/kreon-v24-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/kreon-v24-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/kreon-v24-latin-500.svg#Kreon') format('svg'); /* Legacy iOS */
}

/* kreon-600 - latin */
@font-face {
  font-family: 'Kreon';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/kreon-v24-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/kreon-v24-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/kreon-v24-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/kreon-v24-latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/kreon-v24-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/kreon-v24-latin-600.svg#Kreon') format('svg'); /* Legacy iOS */
}


/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
