@use "sass:color";

.rental {
  border-bottom: 2px solid $clr-yellow-main;
  padding-bottom: 5em;

  &:first-of-type {
    margin-top: 5em;
  }

  @media (min-width: $medium-device) {
    width: 90vw;
    max-width: 1240px;
    margin: 5em auto 0;
    justify-content: center;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 1fr;

    &:first-of-type {
      margin-top: 10em;
    }
  }

  &:last-of-type {
    border: none;
  }

  .rental-text {
    width: 90%;
    margin: 5em auto 0;

    @media (min-width: $medium-device) {
      padding-left: 2em;
      width: unset;
      margin: unset;
    }
  }

  .rental-items {
    width: 90vw;
    margin: 2em auto 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;

    picture {
      img {
        display: block;
      }
    }

    @media (min-width: $medium-device) {
      width: unset;

    }

    &.one {
      grid-template-columns: 1fr;
    }
  }
}