footer {
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 2px solid $clr-yellow-main;
  margin-top: 3em;

  a {
    padding: 0.5em;
    font-family: 'kreon', sans-serif;
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .right {
    margin-top: 1em;
  }
}
