
.opener-arrow {
  animation: jump;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  cursor: pointer;
  height: 50px;
  width: 50px;
  position: relative;
  z-index: 1;
  margin-top: 20px;

  @media (min-width: $medium-device) {
    height: 100px;
    width: 100px;
  }
}


.logo {
  width: 90vw;
  max-width: 1000px;
  margin: 5em auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  display: grid;
  height: 100vh;
  height: var(--app-height);
  overflow: hidden;

  grid-template-rows: auto 1fr auto;

  header {
    @media (min-width: $mobile-nav-breakpoint) {
      height: 5vh;
      transition: all 2s ease;
      transition-delay: 1s;
    }
  }
}
.contact-box {
  display: flex;
  align-items: center;

  .contact-information {
    padding-left: 20px;
    p {
      font-family: $font-family-heading;
    }
  }
}

.triangles {
  margin-bottom: -10px;
  position: sticky;
  bottom: -5px;
  z-index: -1;
}

@keyframes jump {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}