
// MOBILE NAV WITH DETAILS ELEMENT

header {

  details {
    position: relative;
    top: 35px;
    left: calc(90vw - 25px);

    @media (min-width: $mobile-nav-breakpoint) {
      margin-bottom: 10vh;
    }

    hr {
      margin: 0;
    }

    summary {
      outline: none;
      cursor: pointer;
      display: inline-block;
      position: relative;
      z-index: 3;

      width: $mobile-nav-width;
      height: $mobile-nav-height;

      &::-webkit-details-marker {
        display: none;
      }

      &:hover {
        > hr {
          background: lighten($clr-white-full, 20%);
          border: lighten($clr-white-full, 20%);
        }
      }

      > hr {
        height: 2px;
        background: $clr-white-full;
        border: $clr-white-full;
        position: absolute;
        width: 100%;
        transition: all .25s ease-out;

        &:first-child {
          top: 5px;
        }

        &:nth-child(2) {
          top: calc(50% - 2.5px);
        }

        &:nth-child(3) {
          top: calc(100% - 10px);
        }
      }
    }

    &[open] summary > hr {
      &:first-child {
        top: 22px;
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        top: 22px;
        transform: rotate(-135deg);
      }
    }

    nav {
      position: relative;
      background-color: black;
      height: 100vh;
      width: 100vw;
      right: calc(100vw - 10vw - 40px);
      bottom: calc(10vw + 40px);
      z-index: 2;
      padding-top: calc(5vw + 40px);


      ul {
        list-style: none;
        padding: 0;
      }

      a {
        font-size: $h3-font-size;
        font-family: $font-family-heading;
        color: $clr-white-full;
        display: flex;
        align-items: center;
        text-decoration: none;
        padding-top: 1.5em;
        padding-left: 5vw;
        padding-bottom: 15px;
        border-bottom: 1px solid $clr-yellow-main
      }
    }
  }
}

@media (min-width: $mobile-nav-breakpoint) {
  header {
    details {
      all: unset;
      summary {
        display: none;
      }
      nav {
        all: unset;
        padding-top: 3em;
        height: auto;

        ul {
          width: 90vw;
          max-width: $max-content-width;
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          li {
            list-style: none;
          }
        }

        a {
          all: unset;
          display: block;
          text-decoration: none;
          font-family: $font-family-heading;
          font-size: $nav-font-size;
          cursor: pointer;

            &.active {
              color: $clr-yellow-main;
              border-bottom: 1px solid $clr-yellow-main;
            }

            @media (min-width: $medium-device) {
              font-size: $h4-font-size;
            }

            @media (min-width: $medium-device-horizontal) {
              font-size: $h1-font-size;
            }
            &:hover {
              color: $clr-yellow-main;
            }
        }
      }
    }
  }
}

