header {
  display: grid;
  grid-template-rows: repeat(2, 1fr);

  @media (min-width: $medium-device) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    nav {
      ul {
        margin: unset;
        padding: unset;
      }
    }
  }

  .logo-small {
    width: 90vw;
    max-width: 1200px;
    justify-self: center;
    margin-top: 10px;

    @media (min-width: $medium-device) {
      margin-top: 2em;
      margin-bottom: 2em;
      max-width: 1200px;
    }

    @media (min-width: $large-device) {
      margin-top: 2em;
      margin-bottom: 2em;
      width: 50vw;
      max-width: 1200px;
    }
  }
}

#logo_text {
  padding-left: 1px;
}
