/* ---- Colors ---- */
@use "sass:color";
$clr-black-full:    rgb(0, 0, 0);
$clr-black-dark:    rgb(17, 17, 17);
$clr-white-full:    rgb(255, 255, 255);
$clr-yellow-main:   rgb(247, 236, 132);

/* ----- Media Devices ----- */
$small-device:        576px;
$medium-device:       768px;
$large-device:        1024px;
$medium-device-horizontal: 1120px;

/* ----- modular font scaling ----- */

$font_fac: 1.25; // golden
$default-font-size: 1em;
$h4-font-size:          $default-font-size * $font_fac;
$h3-font-size:          $h4-font-size * $font_fac;
$h2-font-size:          $h3-font-size * $font_fac;
$h1-font-size:          $h2-font-size * $font_fac;
$big-heading-font-size: $h1-font-size * 1.3;
$nav-font-size:         $h2-font-size;

$font-reduce_mobil: 0.8;

$font-light:  300;
$font-normal: 400;
$font-bold:   500;

$font-family-heading: "Kreon",  serif;
$font-family-content: "Roboto", sans-serif;

$max-content-width: 1240px;
$mobile-nav-height: 40px;
$mobile-nav-width: 40px;
$mobile-nav-breakpoint: 768px;

:root {
    --app-height: 100%;
}

.frontpage {
  overflow: hidden;
  height: 100vh;
  height: var(--app-height);
}

* {
  box-sizing: border-box;
}

html {
  @media (max-width: $small-device) {
    overflow-x: hidden;
  }
}

body {
  background-color: $clr-black-dark;
  color: $clr-white-full;
  overflow-x: hidden;
}

h1 {
  font-family: $font-family-heading;
  font-weight: $font-bold;
  font-size: $h1-font-size * $font-reduce_mobil;
  @media (min-width: $medium-device) {
    font-size: $h1-font-size;
  }
}

h2 {
  font-family: $font-family-heading;
  font-weight: $font-bold;
  font-size: $h2-font-size * $font-reduce_mobil;
  @media (min-width: $medium-device) {
    font-size: $h2-font-size;
  }
}

h3 {
  font-family: $font-family-heading;
  font-weight: $font-bold;
  font-size: $h3-font-size * $font-reduce_mobil;
  @media (min-width: $medium-device) {
    font-size: $h3-font-size;
  }
}

h4 {
  font-family: $font-family-heading;
  font-weight: $font-bold;
  font-size: $h4-font-size * $font-reduce_mobil;
  @media (min-width: $medium-device) {
    font-size: $h4-font-size;
  }
}

img {
  max-width: 100%;
  height: auto;
}

p {
  font-size: $default-font-size;
  font-family: $font-family-content;
  @media (min-width: $medium-device) {
    font-size: $default-font-size;
  }
}

a {
  color: $clr-white-full;
  text-decoration: none;
  font-family: $font-family-content;
}

a:visited {
  color: $clr-white-full;
  text-decoration: none;
}

li {
  font-size: $default-font-size;
  font-family: $font-family-content;
  line-height: 1.4;
  @media (min-width: $medium-device) {
    font-size: $default-font-size;
  }
}

.hide-on-mobile {
  @media (max-width: $small-device) {
    display: none;
  }
}

@import "start";
@import "content";
@import "animation";
@import "nav";
@import "footer";
@import "rental";
@import "header";
@import "slider";
@import "fonts";



