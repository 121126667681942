 // ------- Slider -------

$slides : #slides_1, #slides_2, #slides_3;

.slider {
  margin: 0 auto;
  max-width: 100%;
  text-align: center;
  position: relative;
}
.slider input[type=radio] {
  display: none;
}
.slider label {
  cursor:pointer;
  text-decoration: none;
}
#{$slides} {
  padding: 5px;
  background: $clr-white-full;
  position: relative;
  z-index: 1;
}
.overflow {
  width: 100%;
  overflow: hidden;
}
#slide1:checked ~ #slides_1 .inner {
  margin-left: 0;
}
#slide2:checked ~ #slides_1 .inner {
  margin-left: -100%;
}
#slide3:checked ~ #slides_1 .inner {
  margin-left: -200%;
}
// ------ Slider 2 ------

#slide5:checked ~ #slides_2 .inner {
  margin-left: 0;
}
#slide6:checked ~ #slides_2 .inner {
  margin-left: -100%;
}
#slide7:checked ~ #slides_2 .inner {
  margin-left: -200%;
}
#slide8:checked ~ #slides_2 .inner {
  margin-left: -300%;
}
// ------ Slider 3 ------

#slide9:checked ~ #slides_3 .inner {
  margin-left: 0;
}
#slide10:checked ~ #slides_3 .inner {
  margin-left: -100%;
}
#slides_1 .inner {
  transition: margin-left 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  width: 400%;
  line-height: 0;
}
#slides_2 .inner {
  transition: margin-left 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  width: 400%;
  line-height: 0;
}
#slides_3 .inner {
  transition: margin-left 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
  width: 400%;
  line-height: 0;
}
#slides_1 .slide {
  width: 25%;
  float:left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: $clr-white-full;
}
#slides_2 .slide {
  width: 25%;
  float:left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: $clr-white-full;
}
#slides_3 .slide {
  width: 25%;
  float:left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: $clr-white-full;
}
.controls {
  width: 100%;
  height: 100%;
  z-index: 3;
  position: absolute;
  top: 0;
}
.controls-container {
  height: 100%;
  width: 100%;
  position: relative;
}
.controls-container label {
  transition: opacity 0.2s ease;
  display: none;
  width: 50px;
  height: 50px;
  opacity: 0.9;
}
#slide1:checked ~ .controls .controls-container label:nth-child(2),
#slide2:checked ~ .controls .controls-container label:nth-child(3),
#slide3:checked ~ .controls .controls-container label:nth-child(1),

#slide5:checked ~ .controls .controls-container label:nth-child(2),
#slide6:checked ~ .controls .controls-container label:nth-child(3),
#slide7:checked ~ .controls .controls-container label:nth-child(4),
#slide8:checked ~ .controls .controls-container label:nth-child(1),

#slide9:checked ~ .controls .controls-container label:nth-child(2),
#slide10:checked ~ .controls .controls-container label:nth-child(1) {
  display: flex;
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-35px, -25px);
  background-color: $clr-white-full;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }

  @media (min-width: $medium-device) {
    height: 80px;
    width: 80px;
    transform: translate(-50px, -40px);
  }
  @media (min-width: $large-device) {
    height: 120px;
    width: 120px;
    transform: translate(-80px, -60px);

  }
  img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 15px 15px 20px;
    @media (min-width: $medium-device) {
      padding: 20px 20px 20px 30px;
    }

    @media (min-width: $large-device) {
      padding: 30px 30px 30px 45px
    }


  }
}