/* ---- Animation ---- */
@keyframes blend-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes blend-out {
  0% {
    opacity: 1;
    //height: 100%;
  }
  50% {
    opacity: 0;
  }
  100% {
    //height: 0;
  }
}

.hide {
  display: none;
}

.fade-out {
  transition: all 4s ease;
  transform: translateY(-100vh);
  animation: blend-out 2s forwards;
}

.text-blend-in {
  animation: blend-in 4s ease;
}
.blend-out {
  opacity: 0;
  transition: opacity 1s ease;
  //animation: blend-out 2s ease;
}

.about-us-text {
  align-self: center;
  justify-self: center;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  padding: 2em;
  z-index: -1;
  hyphens: auto;

  @media (min-width: $large-device) {
    max-width: 1000px;
  }
}

.triangle-fade {
  transition: all 2s ease;
  transform: translateY(60vh);
}

.container details {
  @media(min-width: $mobile-nav-breakpoint) {
    transform: translateY(-10vh);
    transition: all 2s ease;
    transition-delay: 1s;
  }
}

.logo-front-page {
  width: 90vw;
  max-width: 800px;
  margin: 0 auto;
  transform: translateY(15vh);
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $mobile-nav-breakpoint) {
    transform: translateY(45vh);
    margin-bottom: 1em;
  }
}

.logo-front-page-animation {
  transform: translateY(-10vh);
  transition: all 2s ease;
  z-index: -1;

  @media(min-width: $mobile-nav-breakpoint) {
    transform: translateY(13vh);
    transition-delay: 1s;
  }
  @media(min-width: $large-device) {
    transform: translateY(15vh);
  }

  .container details {
    @media(min-width: $mobile-nav-breakpoint) {
      transform: translateY(10vh);
      transition: all 2s ease;
      transition-delay: 1s;
    }
  }
}

.front-nav-animation {
  @media(min-width: $mobile-nav-breakpoint) {
    transform: translateY(10vh);
    transition: all 2s ease;
    transition-delay: 1s;
  }
}
